import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useFormspark } from "@formspark/use-formspark";

import Layout from "../components/Layout";
import { useForm } from "react-hook-form";

const EnquireNowPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    await submit({ data });
    setMessage("Thanks for contacting us! We'll be in touch soon.");
    reset({
      first_name: "",
      last_name: "",
      email: "",
      contact: "",
      group_size: "",
      budget: "",
      message: "",
    });
  };

  const [submit, submitting] = useFormspark({
    formId: "AuTv8UEU",
  });

  const [message, setMessage] = React.useState("");

  return (
    <Layout title="Enquiry Now - Charaiveti">
      <section className="enquire-section">
        <div className="header-wrap">
          <small>Contact Us</small>
          <h1>Enquire Now</h1>
          <p>
            Ready to embark on your Himachal Pradesh adventure? We're here to
            help you plan your dream trip! Whether you have questions, need a
            customized itinerary, or are eager to book your journey, our
            dedicated team is just a click or call away. Contact us now to start
            your unforgettable Himachal experience.
          </p>
        </div>
        <div className="container">
          <div className="enquire-wrap">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="form-group">
                    <label htmlFor="name">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="first_name"
                      placeholder="Raghav"
                      {...register("first_name", { required: true })}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="form-group">
                    <label htmlFor="name">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="last_name"
                      placeholder="Mishra"
                      {...register("last_name", { required: true })}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="form-group">
                    <label htmlFor="name">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder="example@mail.com"
                      {...register("email", { required: true })}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="form-group">
                    <label htmlFor="name">Contact</label>
                    <input
                      type="text"
                      className="form-control"
                      id="contact"
                      placeholder="7000070000"
                      {...register("contact", { required: true })}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="form-group">
                    <label htmlFor="name">Group Size</label>
                    <input
                      type="number"
                      className="form-control"
                      id="group_size"
                      placeholder="10"
                      {...register("group_size", { required: true })}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="form-group">
                    <label htmlFor="name">Budget per Person</label>
                    <input
                      type="number"
                      className="form-control"
                      id="budget"
                      placeholder="12000"
                      {...register("budget", { required: true })}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="form-group">
                    <label htmlFor="name">Message</label>
                    <textarea
                      id="message"
                      placeholder="Please tell us more about your query..."
                      {...register("message", { required: true })}
                    ></textarea>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 btn-wp">
                  <button type="submit" className="btn btn-dark-outline">
                    Submit
                  </button>
                  {message && <span className="text-green-500">{message}</span>}
                </div>
              </div>
            </form>

            <div className="call-us-wrap">
              <h2>or just Call us!</h2>
              <p>
                Whether you have questions about our products, services, or need
                assistance, our dedicated team is here for you. Pick up the
                phone and connect with us today.
              </p>

              <div className="contact-item">
                <i className="ri-phone-fill"></i>
                <p>Call us at</p>
                <a href="tel:+91 8076560633">+91 80765-60633</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default EnquireNowPage;
